import Quill, { Parchment } from "quill";
import { Attributor } from 'parchment';
import {EditorComponent} from "./editor.component";

export class RNGQLAutosave {
    private static quill: any;
    private options: any;

    private onForceSaveClickCallback;
    private onDocumentDirtyCallback;
    private onDocumentSaved;
    private updateFullExternalDocument;
    private editorComponent: EditorComponent;

    constructor(quillOb, options) {
        RNGQLAutosave.quill = quillOb;
        this.options = options;

        this.onForceSaveClickCallback = this.options.onForceSaveClickCallback;
        this.onDocumentDirtyCallback = this.options.onDocumentDirtyCallback;
        this.onDocumentSaved = this.options.onDocumentSaved;
        this.editorComponent = this.options.editorComponent;
        this.updateFullExternalDocument = this.options.updateFullExternalDocument;

        this.editorComponent.setReferenceToAutosaveModule(this);

        const forceSave = this.forceSave;
        const localOb = this;

        // for comment color on/off toolbar item
        let toolbar = RNGQLAutosave.quill.getModule('toolbar');
        if (toolbar) {
            toolbar.addHandler('force-save', async function() {
                console.log('force-save handler called!');
                await forceSave(localOb);
            });
        } else {
            console.log('Error: RNGQLAutosave.quill-autosave module needs toolbar');
        }

    }

    private timeoutId;

    public flagDocumentDirty() {
        console.log('Autosave flagging dirty');
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
            this.doSave();
        }, this.options.timeout);
        console.log('SAVE TIMEOUTID: ', this.timeoutId);
        this.onDocumentDirtyCallback(this);
    }

    async doSave() {
        console.log('doSave called');
        await this.updateFullExternalDocument(RNGQLAutosave.quill.getContents(), this);
        this.onDocumentSaved(this);
    }

    async forceSave(localOb = this) {
        await localOb.doSave();
        //this.onForceSaveClickCallback(localOb);
    }

}


