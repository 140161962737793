import Quill, { Parchment } from "quill";
import { Attributor } from 'parchment';
import {CommentIconBlot} from "./CommentIconBlot";
import {EditorComponent} from "../editor/editor.component";

export class WPSQLComments {
    private static quill: any;
    private options: any;

    private isEnabled: boolean = true;

    private Parchment = Quill.import('parchment');
    private editorComponent: EditorComponent;

    private CommentAttr = new Attributor('comment', 'ql-comment', {
        scope: this.Parchment.Scope.INLINE
    });

    private CommentFGAttr = new Attributor('commentfg', 'ql-comment-fg', {
        scope: this.Parchment.Scope.INLINE
    });

    private CommentIcon = new Attributor('commentIcon', 'ql-comment-icon', {
        scope: this.Parchment.Scope.INLINE
    });

    private CommentAuthorUUIDAttr = new Attributor('commentAuthorUUID', 'ql-comment-author-uuid', {
        scope: Parchment.Scope.INLINE
    });

    private CommentAuthorRoleAttr = new Attributor('commentAuthorRole', 'ql-comment-author-role', {
        scope: Parchment.Scope.INLINE
    });

    private CommentAuthorScreenNameAttr = new Attributor('commentAuthorScreenName', 'ql-comment-author-screen-name', {
        scope: Parchment.Scope.INLINE
    });

    private CommentTimestampAttr = new Attributor('commentTimestamp', 'ql-comment-timestamp', {
        scope: Parchment.Scope.INLINE
    });

    private CommentId = new Attributor('commentId', 'id', {
        scope: Parchment.Scope.INLINE
    });

    private CommentReplies = new Attributor('commentReplies', 'ql-comment-replies', {
        scope: Parchment.Scope.INLINE
    });

    private styleElement: HTMLStyleElement;

    constructor(quillOb, options) {
        WPSQLComments.quill = quillOb;
        this.options = options;

        Quill.register(this.CommentId, true);
        Quill.register(this.CommentFGAttr, true);
        Quill.register(this.CommentIcon, true);
        Quill.register(this.CommentAttr, true);
        Quill.register(this.CommentAuthorUUIDAttr, true);
        Quill.register(this.CommentAuthorRoleAttr, true);
        Quill.register(this.CommentAuthorScreenNameAttr, true);
        Quill.register(this.CommentTimestampAttr, true);
        Quill.register(this.CommentReplies, true);
        Quill.register(CommentIconBlot, true);

        let commentAddClick = this.options.commentAddClick;
        let commentToggleUpdate = this.options.commentToggleUpdate;
        this.editorComponent = this.options.editorComponent;
        this.editorComponent.setReferenceToCommentModule(this);
        let addComment = this.addComment;

        let commentObj = this;

        // for comment color on/off toolbar item
        let toolbar = WPSQLComments.quill.getModule('toolbar');
        console.log('Toolbar: ', toolbar);
        if (toolbar) {
            toolbar.addHandler('comments-toggle', function() {
                console.log('comments-toggle handler called!');
                commentObj.enable(!commentObj.isEnabled);

                if (commentToggleUpdate) {
                    commentToggleUpdate(commentObj.isEnabled);
                }
            });
            toolbar.addHandler('comments-add', function() {
                console.log('comments-add handler called!');
                this.range = WPSQLComments.quill.getSelection();

                if (!this.range || this.range.length ==0) {
                    return; // do nth, cuz nothing is selected
                }
                commentAddClick(addComment,this);
            });

        } else {
            console.log('Error: WPSQLComments.quill-comment module needs WPSQLComments.quill toolbar');
        }

        // to prevent comments from being copied/pasted.
        WPSQLComments.quill.clipboard.addMatcher('span[ql-comment]', function(node, delta) {

            delta.ops.forEach(function(op) {
                op.attributes["comment"] && delete op.attributes["comment"];
                op.attributes["commentAuthorRole"] && delete op.attributes["commentAuthorRole"];
                op.attributes["commentAuthorUUID"] && delete op.attributes["commentAuthorUUID"];
                op.attributes["commentAuthorScreenName"] && delete op.attributes["commentAuthorScreenName"];
                op.attributes["commentId"] && delete op.attributes["commentId"];
                op.attributes["commentTimestamp"] && delete op.attributes["commentTimestamp"];
                op.attributes["background"] && delete op.attributes["background"];
                op.attributes["commentfg"] && delete op.attributes["commentfg"];
                op.attributes["commentIcon"] && delete op.attributes["commentIcon"];
                op.attributes["commentReplies"] && delete op.attributes["commentReplies"];

            });
            return delta;
        });
    }

    updateCommentReplies(comment: Comment) {
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentReplies', JSON.stringify(comment.replies));
        //Need to update the comment Blot so it removes the format when removed and update it
        const el = document.getElementById('ICON-'+comment.uuid);
        console.log('EL: ', el);
        el.setAttribute('data',JSON.stringify(comment));
    }

    removeComment(comment: Comment) {
        if (!comment || !comment.comment) {
            return; // cannot work without comment
        }

        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'background', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentfg', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorUUID', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorScreenName', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorRole', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentIcon', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentTimestamp', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentId', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'comment', false);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentReplies', false);
        WPSQLComments.quill.deleteAt(comment.range.index+comment.range.length,1,Quill.sources.USER);
    }

    addComment(comment: Comment) {

        if (!comment || !comment.comment) {
            return; // cannot work without comment
        }

        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'background', comment.bgcolor);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentfg', comment.fgcolor);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorUUID', comment.authorUUID);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorScreenName', comment.authorScreenName);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentAuthorRole', comment.authorRole);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentIcon', comment.icon);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentTimestamp', comment.timestamp.getTime());
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentId', comment.uuid);
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'comment', comment.comment);
        if (!comment.replies) {
            comment.replies = [];
        }
        WPSQLComments.quill.formatText(comment.range.index, comment.range.length, 'commentReplies', JSON.stringify(comment.replies));
        WPSQLComments.quill.insertEmbed(comment.range.index+comment.range.length, 'marker', comment, Quill.sources.USER);
        setTimeout(() => WPSQLComments.quill.setSelection(comment.range.index), 0);
    }

    enable(enabled = true) {
        this.isEnabled = enabled;
    }

    disable() {
        this.enable(false);
    }

}

export class Comment {
    public hidden: boolean = false;
    public comment: string;
    public authorUUID: string;
    public authorRole: string;
    public authorScreenName: string;
    public bgcolor: string;
    public fgcolor: string;
    public range: {index: number | null, length: number | null};
    public timestamp: Date;
    public icon: string;
    public replies: Reply[];
    public callback: any;
    public uuid: string;
}

export class Reply {
    public hidden: boolean = false;
    public comment: string;
    public authorUUID: string;
    public authorRole: string;
    public authorScreenName: string;
    public bgcolor: string;
    public fgcolor: string;
    public timestamp: Date;
    public icon: string;
    public replies: Reply[]; //We'll use this when ready to turn this to a tree
}

