import Quill, {Parchment} from "quill";
import {Comment} from "./WPS_QL_Comments";

export class CommentIconBlot extends Parchment.EmbedBlot {
    static iconClass: string;
    static iconPrefix: string;

    static override create(value) {
        let node = super.create(value);
        console.log('NEW COMMENT:', value);
        node.addEventListener('click', function(e) {
            // e.target should always be node since it is attached to node
            const blot: Parchment.Blot = <Parchment.Blot>Quill.find(node);
            const offset = blot.offset(blot.scroll);
            window['angularComponentRef'].zone.run(
                () => {
                    window['angularComponentRef'].showComment(blot, offset);
                }
            );
        });
        CommentIconBlot.buildSpan(value, node);
        return node;
    }

    static override value(node) {
        console.log('NODE: ', node);
        console.log('NODE DATA: ', node.getAttribute('data'));
        return JSON.parse(node.getAttribute('data'));
    }

    static buildSpan(value: Comment, node) {
        node.setAttribute('data', JSON.stringify(value));
        node.id = 'ICON-'+value.uuid;
        node.classList.add('base-comment-icon');
        node.classList.add('comment-icon-'+value.authorUUID);
        node.classList.add(this.iconClass);
        node.classList.add(this.iconPrefix + value.icon);
        node.style.verticalAlign = 'super';
        node.style.fontSize = '0.83em';
        node.style.color = value.fgcolor;

        //node.appendChild(iconSpan);
    }
}

CommentIconBlot.blotName = 'marker';
CommentIconBlot.className = 'ql-iconblot';
CommentIconBlot.tagName = 'i';
CommentIconBlot.iconClass = 'pi';
CommentIconBlot.iconPrefix = 'pi-';
